import RIDB from './RIDB'
import DateEngine from './DateEngine'

/* TODO
- this.filter()
    - Refine the data structure of results
    - Figure out what to do with each status type (FF, Not Available due to closed)
- 

*/

// class campground = {
//     constructor(campsiteData, yearMonth) {
//         this._monthsOfData = [yearMonth];
//         this._data = data;
//     },
//     get monthsOfData
// }

const AvailabilityDataStore = {
    facilities: {},
    // This is used externally 
    // TODO: Make this accept an array of campgrounds and place names
    async query(facilityID, nlDateProperties, criteria) {
        // URL Date Range split character
        const drSpliter = '--';
        // Process natural language date properties
        let desiredDateRanges;
        if ('userDefinedDateRanges' in nlDateProperties && !(nlDateProperties.userDefinedDateRanges === undefined || nlDateProperties.userDefinedDateRanges === null)) {
            // Check if it is an array of two-date arrays or if it is a string that needs parsing
            desiredDateRanges = nlDateProperties.userDefinedDateRanges.map(dateRange => {
                if (typeof dateRange === 'string' && typeof dateRange.includes(drSpliter)) {
                    return dateRange.split(drSpliter);
                }
                else {
                    return dateRange;
                }
            })
        }
        else if (!('dateSearchWindow' in nlDateProperties)) {
            // If no dateSearchWindow is defined, it implies that we need to find the next available meeting criteria
            // This utility doesn't actually account for this. See NextAvailable util. Should load instead of this when looking for next available.
            this.getNextAvailable(nlDateProperties);
        }
        else {
            // In the other cases, date ranges need to be generated from natural language date properties
            desiredDateRanges = DateEngine.nlDatePropertiesToDateRanges(nlDateProperties);
        }
        // Make sure we have date ranges to work with
        if (desiredDateRanges === undefined || !(desiredDateRanges.length > 0)) {
            console.warn('Error: No dates entered or failed to generate date ranges for search');
            return [];
        }
        // console.log('Desired date ranges:', desiredDateRanges);

        // See if the data store has data
        const desiredYearMonths = DateEngine.getUniqueYearMonths(desiredDateRanges);
        const yearMonthsNeededForDataStore = [];
        desiredYearMonths.forEach(yearMonth => {
            if (!this.hasData(facilityID, yearMonth)) {
                // console.log(`dataStore does not have ${facility}, ${yearMonth}`)
                yearMonthsNeededForDataStore.push(yearMonth)
            }
        })
        if (yearMonthsNeededForDataStore.length > 0) {
            // console.log('start of fetching');
            const promises = yearMonthsNeededForDataStore.map(async (yearMonth) => {
                const availability = await RIDB.fetch(facilityID, yearMonth);
                return {
                    availability,
                    facility: facilityID,
                    yearMonth
                }
            })

            const availabilities = await Promise.all(promises)
            console.log('Fetching complete', availabilities)

            if (typeof availabilities === 'undefined' || Object.keys(availabilities).length === 0) { return }
            availabilities.forEach((result) => {
                this.mergeData(result.availability, result.facility, result.yearMonth)
            });

        }

        // We should now have all our needed data client-side
        else {
            console.log(`Data store has the data. No need to fetch.`)
        }
        // Just to double check?
        // yearMonthsNeededForDataStore.forEach(yearMonth => {
        //     console.log(`Now I have all the data for ${yearMonth}`, this.hasData(facility, yearMonth))
        // });

        // Now, filter the merged/completed data set
        console.log('Current Availability Data Store', this.facilities);

        const filteredResults = this.getMatchingAvailabilityResults(facilityID, desiredDateRanges)
        console.log(`Filtered results for ${facilityID}`, filteredResults);
        return filteredResults


        // TODO: Overhaul the data model for filtered results for optimal display
        // const filteredResults = this.filter(this.campgrounds[campground].campsites, campground, desiredDateRanges, criteria);
        // console.log(`Filtered results`);
        // filteredResults.forEach(result => {
        //     console.log(`Campground ${result.campground}, there are ${result.campsites.length} sites available`);
        // })
        // console.log('Details', filteredResults);
        // return filteredResults;
    },
    mergeData(facilityAvailability, facility, yearMonth) {
        // console.log('Merging data into data store');
        // console.log('Current data store', this.campgrounds)

        // Sometimes the API returns "Unauthorized access" for some reason
        // Nevermind. This cause infinit querying...
        // if (!('campsites' in facilityAvailability) || facilityAvailability.message === "Unauthorized Access" ) {
        //     return
        // }

        // Need to create/update the entry of {campground} into the campgrounds object
        // Then we need to add {yearMonth} to [campground].months array
        // Then we need to merge the new availability date data for each campsite 
        // Check if this campground already exists in the dataStore
        // TODO: Change this to use a constructor of a class
        if (!this.facilities[facility]) {
            // console.log('this.campgrounds[campground] does not exist yet');
            this.facilities[facility] = {
                monthsOfData: [yearMonth],
                campsites: facilityAvailability
            }
        }
        else {
            // console.log('this.campgrounds[campground] does exist');
            this.facilities[facility].monthsOfData.push(yearMonth);
            // For each campsite, merge the availabilities data
            for (const campsite in facilityAvailability) {
                // Merge
                this.facilities[facility].campsites[campsite].availabilities = Object.assign(this.facilities[facility].campsites[campsite].availabilities, facilityAvailability[campsite].availabilities);
            }
        }
    },
    hasData(facility, yearMonth) {
        if (typeof this.facilities[facility] === 'undefined') {
            return false;
        }
        if (this.facilities[facility].monthsOfData.includes(yearMonth)) {
            return true;
        }
        return false;
    },
    getNextAvailable(nlDateProperties) {
        // TODO: Write a loop that gets the next applicable date range, then queries for data
        // It will break when one or more available sites are found or nextAvailabelThreshold (six months) have been reached
        // It will hammer the query function, but it should only fetch for new data from RIDB when a new month of data is required
    },
    getMatchingAvailabilityResults(desiredFacility, desiredDateRanges, criteria) {
        const emptyResults = [];
        if (desiredDateRanges.length === 0) { return emptyResults }
        if (!(this.facilities[desiredFacility])) {
            console.log(`Error: Availability data for facility ${desiredFacility} was not received`);
            return emptyResults;
        }
        // Not all facilities have campsites (e.g., 10118203)
        if (this.facilities[desiredFacility].campsites.length === 0) {
            console.log(`Facility ${desiredFacility} does not have any campsites`);
            // TODO: Stop unnecessary queries for availabilitiy for facilities without campsites
            // TODO: Differentiate or filter out the facilities without campsites
            return emptyResults;
        }

        // Convert date ranges to date sets

        // Result hierarchy: campgrounds > campground > availableDates > [{date: '', campsites: 1}]
        let result = {}

        result = {
            desiredDateRanges: [], // Returned info for each desired date
            calendarDatesWithAvailability: {}
        };

        // Since the next level of my results hierarchy is date ranges available, find campsites for each desiredDateRange
        desiredDateRanges.forEach(desiredDateRange => {
            // Since the data store (ie RIDB) is structured campsites[campsite].availabilties[date]...
            // For each campsite, see if it meets the criteria (eg, hookups) and is available for the whole date range
            let campsitesAvailable = [];
            // TODO: Add in "hacker" info (the desired date range is available but would require hopping sites)
            // Note: DateEngine.dateRangesToDateSets requires an array of dateRanges
            const desiredDateSets = DateEngine.dateRangesToDateSets([desiredDateRange]);
            const desiredDateSet = desiredDateSets[0];
            for (const campsite in this.facilities[desiredFacility].campsites) {
                // TODO: Check if the campsite meets other criteria (eg, hookup requirement, ADA)
                // If not, break
                let datesAvailable = [];
                desiredDateSet.forEach(desiredDate => {
                    if (desiredDate.length === 0) { return };
                    const desiredDateFormatted = desiredDate + 'T00:00:00Z';
                    const availabilityValue = this.facilities[desiredFacility].campsites[campsite].availabilities[desiredDateFormatted];
                    const dateIsAvailable = availabilityValue === 'Available';
                    const dateIsNotReservable = availabilityValue === 'Not Reservable';
                    if (dateIsAvailable) {
                        datesAvailable.push(desiredDate);
                    }
                    else if (dateIsNotReservable) {
                        // TODO: Inform the user that it isn't that the sites aren't all booked. Rather they simply aren't available
                        // Tell user WHY they aren't available (eg, campground is closed in winter months, or date range isn't available for booking yet)
                    }
                });
                if (datesAvailable.length === desiredDateSet.length) {
                    // console.log(`campsite ${campsite} is available `, datesAvailable);
                    // Add this campsite as one that is available for the desiredDateRange
                    campsitesAvailable.push(campsite);
                }
            }

            // Add an entry for each desired date range... then we will filter afterward
            result.desiredDateRanges.push({
                dateArray: desiredDateRange,
                shortFormDateRange: DateEngine.formatDateRange([desiredDateRange[0], desiredDateRange[1]]),
                checkinDate: desiredDateRange[0],
                checkoutDate: desiredDateRange[1],
                datesOfStay: desiredDateSet,
                numberOfNights: desiredDateSet.length,
                campsitesAvailable: campsitesAvailable
            });
        })
        // Now that we have the campsite availability for each date, summarize the calendar dates with availability for use of facility cards
        // If there are available campsites, record the dates in the calendarDatesWithAvailability
        result.desiredDateRanges.forEach(resultDateRange => {
            if (resultDateRange.campsitesAvailable.length > 0) {
                // Create entries for each date from the date set
                resultDateRange.datesOfStay.forEach(desiredDate => {
                    // If the desiredDate isn't in the calendar yet, add it
                    if (!(desiredDate in result.calendarDatesWithAvailability)) {
                        result.calendarDatesWithAvailability[desiredDate] = {
                            intersectingDateSets: [{
                                dateSet: resultDateRange.datesOfStay,
                                campsitesAvailable: resultDateRange.campsitesAvailable
                            }]
                        }
                    }
                    else {
                        result.calendarDatesWithAvailability[desiredDate].intersectingDateSets.push({
                            dateSet: resultDateRange.datesOfStay,
                            campsitesAvailable: resultDateRange.campsitesAvailable
                        });
                    }

                })
            }
        })
        return result
    },
}

export default AvailabilityDataStore;